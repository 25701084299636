import React from 'react'
import PropTypes from 'prop-types'

/*jshint esversion: 6 */

import Jstz from "jstimezonedetect"
import moment from 'moment-timezone'
import SubsurveyRow from "./subsurvey_row.jsx"

class SelectSubsurvey extends React.Component {
  static propTypes = {
    action: PropTypes.string
  }

  constructor(props) {
    super(props)
    this.state = {
      subsurveys: []
    }
  }

  componentDidMount() {
    this.loadSubsurveys();

    $(window).on("submitSubsurvey", this.loadSubsurveys.bind(this));
  }

  loadSubsurveys() {
    $.ajax({
      method: "GET",
      url: this.props.action,
      cache: false,
      context: this
    })
    .done(function(response) {
      var subsurveys = response.subsurveys.map(subsurvey => {
        subsurvey.isSelected = true;
        return subsurvey;
      });

      this.setState({
        subsurveys: subsurveys
      });
    });
  }

  toggleSelect(toggledSubsurvey) {
    var subsurveys;

    subsurveys = this.state.subsurveys.map(subsurvey => {
      if (toggledSubsurvey.id === subsurvey.id) {
        subsurvey.isSelected = !subsurvey.isSelected;
      }
      return subsurvey;
    });

    var numberSelected = subsurveys.reduce((accumulator, subsurvey) => {
      var add = subsurvey.isSelected ? 1 : 0;
      return accumulator + add;
    }, 0);

    this.setState({
      subsurveys: subsurveys
    });
  }

  render() {
    return (
      <div>
        <table className="table table-hover table-responsive-lg">
          <thead>
            <tr>
              <th scope="col"><i className="far fa-2x fa-plus-square"></i></th>
              <th scope="col">Survey Name</th>
              <th scope="col"><i className="far fa-2x fa-calendar"></i></th>
              <th scope="col"><i className="far fa-2x fa-trash-alt"></i></th>
            </tr>
          </thead>
          <tbody>
            {this.state.subsurveys.map((subsurvey) =>
              <SubsurveyRow action={this.props.action} subsurvey={subsurvey} loadSubsurveys={this.loadSubsurveys} toggleSelect={this.toggleSelect} key={"subsurvey_"+subsurvey.id}/>
            )}
          </tbody>
        </table>
        {this.state.subsurveys.filter((subsurvey) => subsurvey.isSelected)
          .map((subsurvey) => <input type="hidden" name="subsurvey_ids[]" value={subsurvey.id} key={"subsurvey_selected_"+subsurvey.id} />
        )}
      </div>
    );
  }
}

export default SelectSubsurvey
