import React from 'react'
import PropTypes from 'prop-types'

class AjaxForm extends React.Component {
  static propTypes = {
    action: PropTypes.string,
    confirmMessage: PropTypes.string,
    encType: PropTypes.string,
    name: PropTypes.string,
    method: PropTypes.string,
    onError: PropTypes.func,
    onSuccess: PropTypes.func,
    id: PropTypes.string
  }

  constructor(props) {
    super(props)
    this.state = {
      encType: "application/x-www-form-urlencoded",
      method: "POST"
    }
  }

  clearInputs() {
    $(':input','form[name="'+this.props.name+'"]')
      .not(':button, :submit, :reset, :hidden')
      .val('')
      .removeAttr('checked')
      .removeAttr('selected');
  }

  onSubmit(e) {
    // Use ajax to submit forms so we can properly add csrf tokens to all form submits
    // Even if we reload (for now), this will let us do csrf easily inside of React components
    e.preventDefault();

    if (this.props.confirmMessage) {
      if (!confirm(this.props.confirmMessage)) {
        return;
      }
    }

    $.ajax({
      method: "POST",
      data: $(e.target).serialize(),
      url: this.props.action,
      error: this.onError,
      success: this.onSuccess.bind(this)
    });
  }

  onError(response) {
    this.props.onError(response);
  }

  onSuccess(response) {
    this.clearInputs();
    this.props.onSuccess(response);
  }

  render() {
    return (
      <form style={this.props.style} name={this.props.name} encType={this.props.encType} method={this.props.method} action={this.props.action} onSubmit={this.onSubmit.bind(this)} id={this.props.id}>
        {this.props.children}
      </form>
    );
  }
}

export default AjaxForm
