import React from 'react'
import PropTypes from 'prop-types'

// Show map of region
// Show all the dots that are unresponded to
// When you click on a dot, you should see
// When you click on 'respond', be able to choose to respond to it
import Humanize from "humanize-plus"
import 'mapbox-gl/dist/mapbox-gl.css'
import mapboxgl from 'mapbox-gl'
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default

class IncidentMap extends React.Component {
  static propTypes = {
    incidents: PropTypes.array,
    polygons: PropTypes.array,
    getUserLocation: PropTypes.bool
  }

  // Elements: map, point, geoJSON polygons
  getMap()  {
    if (this.props.incidents.length == 1) {
      var center = [this.props.incidents[0].lng, this.props.incidents[0].lat]
    } else {
      var center = [window.default_lng, window.default_lat]
    }
    mapboxgl.accessToken = 'pk.eyJ1IjoiYXBwbGVkb3JlIiwiYSI6ImNpcDI4bHl2dDAwMHV0c20wczlrMGN3cHEifQ.ZTVATzrND2lnLvC9GCX5zw';
    if(!this.map) {
      var map = new mapboxgl.Map({
        container: 'map', // container id
        style: 'mapbox://styles/mapbox/dark-v10', // stylesheet location
        center: center, // starting position [lng, lat]
        zoom: 9 // starting zoom
      });
      this.map = map;
    }
    return this.map;
  }

  composeGeoJsonFeatures() {
    const { incidents } = this.props;

    var incidentGeoJsonArray = [];
    incidents.forEach(function(incident) {
      incidentGeoJsonArray.push({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [incident.lng, incident.lat]
        },
        properties: {
          id: incident.id,
          title: incident.incident_type,
          description: incident.description,
          address_display_name: incident.address_display_name
        }
      })
    })

    return {
      type: "FeatureCollection",
      features: incidentGeoJsonArray
    }
  }

  getUserLocateControls() {
    const { getUserLocation } = this.props;
    var map = this.getMap();
    if(getUserLocation) {
      var geolocateControl = new mapboxgl.GeolocateControl({
        positionOptions: {
        enableHighAccuracy: true
        },
        trackUserLocation: true
      })
      map.addControl(geolocateControl);
      map.on('load', function()
        {
        	geolocateControl.trigger();
        }
      );
    } else {
      var geolocateControl = new mapboxgl.GeolocateControl({
        positionOptions: {
        enableHighAccuracy: true
        },
        trackUserLocation: true
      })
      map.addControl(geolocateControl);
    }
  }

  componentDidMount() {
    var map = this.getMap();
    var canvas = map.getCanvasContainer();
    this.getUserLocateControls();
    const { polygons, incidents } = this.props;

    var geojsonFeatures = this.composeGeoJsonFeatures();

    geojsonFeatures.features.forEach(function(marker) {
      // make a marker for each feature and add to the map
      if(marker.properties.description != null) {
        var shortenedDescription = marker.properties.description.substr(0,50)
      } else {
        var shortenedDescription = "No description."
      }

      var title = Humanize.capitalize(marker.properties.title).replace("_", " ")


      if(marker.properties.address_display_name === null) {
        var address_display_name = "No location available"
      } else {
        var address_display_name = marker.properties.address_display_name
      }
      new mapboxgl.Marker()
        .setLngLat(marker.geometry.coordinates)
        .setPopup(new mapboxgl.Popup({ offset: 15 }) // add popups
        .setHTML('<h5>' + title + '</h5>' + '<i>Near ' + marker.properties.address_display_name + "<br/></i>" + shortenedDescription + "..." + '</br><hr/><a class="btn btn-secondary" href="' + '/dashboard/incidents/' + marker.properties.id + ' ">View Incident</a>'))
        .addTo(map);
    });
  }

  render() {
    return(
      <div>
        <div id='map'></div>
      </div>
    )
  }
}

export default IncidentMap