import React from 'react'
import PropTypes from 'prop-types'

// Change workflow state
// Add new user and remove user
import Humanize from "humanize-plus"
import AjaxForm from "./ajax_form.js.jsx"
import Comments from "./comments.jsx"

class ActionItemUpdateWorkflowState extends React.Component {
  static propTypes = {
    actionItem: PropTypes.object,
    actionItemUpdateUrl: PropTypes.string,
    updateActionItemUserUrl: PropTypes.string,
  }

  componentDidMount() {
  }

  changeWorkflowState(choice, color, action) {
    var inlineStyle = {display: 'inline'};
    return (
      <AjaxForm style={inlineStyle} name="action_item" action={this.props.actionItemUpdateUrl} method="post">
        <input type="hidden" name="_method" value="put" />
        <input type="hidden" name="workflow_state" value={choice} />
        <input type="hidden" name="action_item_id" value={this.props.actionItem.id} />
        <button type="submit" className={"btn form-control btn-gradient-" + color}>{action + " Task"} </button>
      </AjaxForm>
    );
  }

  optionWorkflowState() {
    var inlineStyle = {display: 'inline'};
    switch (this.props.actionItem.workflow_state) {
      case 'new': return (
        <div>
          <div className="col-lg-6 col-md-6">
            {this.changeWorkflowState('acknowledge!', 'warning', 'Acknowledge')}
            <p className="text-muted">
              Acknowledge a task to let your collegues know that you're aware of the task and will begin work on it at your earliest convenience.
            </p>
          </div>
          <div className="col-lg-6 col-md-6">
            {this.changeWorkflowState('close!', 'secondary', 'Close')}
            <p className="text-muted">
              Close out a task when there's nothing left for you to do or have put the task on hold. Indicate in the comments why the task was closed.
            </p>
          </div>
        </div>
      );
      case 'acknowledged': return (
        <div>
          <div className="row">
            <div className="col-lg-6 col-md-6">
              {this.changeWorkflowState('start!', 'info', 'Start')}
              <p className="text-muted">
                Start a task when you've actually begun work on the task; this helps people know you're on the job.
              </p>
            </div>
            <div className="col-lg-6 col-md-6">
              {this.changeWorkflowState('close!', 'secondary', 'Close')}
              <p className="text-muted">
                Close out a task when there's nothing left for you to do or have put the task on hold. Indicate in the comments why the task was closed.
              </p>
            </div>
          </div>
        </div>
      );
      case 'in_progress': return (
        <div>
          <div className="row">
            <div className="col-lg-6 col-md-6">
              {this.changeWorkflowState('complete!', 'success', 'Complete')}
              <p className="text-muted">
                Complete the task when you've finished up your work.
              </p>
            </div>
            <div className="col-lg-6 col-md-6">
              {this.changeWorkflowState('close!', 'secondary', 'Close')}
              <p className="text-muted">
                Close out a task when there's nothing left for you to do or have put the task on hold. Indicate in the comments why the task was closed.
              </p>
            </div>
          </div>
        </div>
      );
      case 'completed': return (
        <div>
          <div className="card card-body" style={{'margin-top':'0px'}}>
            This task has been completed. Reopen the task when you're ready to pick up this task again.
            <p></p>
            {this.changeWorkflowState('reopen!', 'secondary', 'Reopen')}
          </div>
        </div>
      );
      case 'closed': return (
        <div>
          <div className="card card-body" style={{'margin-top':'0px'}}>
            This task has been closed. Reopen the task when you're ready to pick up this task again.
            <br />
            {this.changeWorkflowState('reopen!', 'secondary', 'Reopen')}
          </div>
        </div>
      );
    }
  }

  evaluateCompletion(status) {
    if(status === 'new') {
      if(['new', 'acknowledged', 'in_progress', 'completed'].includes(this.props.actionItem.workflow_state)) {
        return "is-complete";
      }
    }else if(status === 'acknowledged') {
      if(['acknowledged', 'in_progress', 'completed'].includes(this.props.actionItem.workflow_state)) {
        return "is-complete";
      }
    }else if(status === 'in_progress') {
      if(['in_progress', 'completed'].includes(this.props.actionItem.workflow_state)) {
        return "is-complete";
      }
    }else if(status === 'completed') {
      if(['completed'].includes(this.props.actionItem.workflow_state)) {
        return "is-complete";
      }
    }
  }

  render() {
    return (
      <div>
        <p className="roboto">Change status</p>
        <p className="text-muted">Keep the status of the task up to date. Whenever you update the status by submitting an update below, we will notify anyone tagged that you're working on this task and keep them updated on your progress.</p>
        <ol className="ProgressBar">
          <li className={"ProgressBar-step " + this.evaluateCompletion('new')}>
            <svg className="ProgressBar-icon"><use xlinkHref="#checkmark-bold"/></svg>
            <span className="ProgressBar-stepLabel">New</span>
          </li>
          <li className={"ProgressBar-step " + this.evaluateCompletion('acknowledged')}>
            <svg className="ProgressBar-icon"><use xlinkHref="#checkmark-bold"/></svg>
            <span className="ProgressBar-stepLabel">Acknowledged</span>
          </li>
          <li className={"ProgressBar-step " + this.evaluateCompletion('in_progress')}>
            <svg className="ProgressBar-icon"><use xlinkHref="#checkmark-bold"/></svg>
            <span className="ProgressBar-stepLabel">In Progress</span>
          </li>
          <li className={"ProgressBar-step " + this.evaluateCompletion('completed')}>
            <svg className="ProgressBar-icon"><use xlinkHref="#checkmark-bold"/></svg>
            <span className="ProgressBar-stepLabel">Completed</span>
          </li>
        </ol>
        <p></p>
        {this.optionWorkflowState()}
      </div>
    );
  }
}

export default ActionItemUpdateWorkflowState
