import React from 'react'
import PropTypes from 'prop-types'

import AjaxForm from "./ajax_form.js.jsx"

class AddGrant extends React.Component {
  static propTypes = {
    action: PropTypes.string,
    loadGrants: PropTypes.func,
    programs: PropTypes.array
  }

  constructor(props) {
    super(props)
    this.state = {
      showDates: true
    }
  }

  onError(response) {
    alert(response.responseJSON.error);
    this.props.loadGrants();
  }

  onSuccess(response) {
    this.props.loadGrants();
  }

  // TODO: Verify dates

  renderDates() {
    return (
      <div>
        <fieldset className="form-group">
          <input className="form-control datepicker grant-start-at" name="grant_start_at" type="date" placeholder="Grant Start Date"></input>
        </fieldset>
        <fieldset className="form-group">
          <input className="form-control datepicker grant-end-at" name="grant_end_at" type="date" placeholder="Grant End Date"></input>
        </fieldset>
      </div>
    );
  }

  renderSelectPrograms() {
    var makeOption = function(program) {
      return (
        <option name={program.name} key={"program_"+program.id}>
          {program.name}
        </option>
      );
    };

    return (
      <fieldset className="form-group">
        <select name="program_name" className="form-control">
          {this.props.programs.map(makeOption)}
        </select>
      </fieldset>
    );
  }

  componentDidMount() {
    $('input[type=date]')
    .attr('type', 'text')
    .datepicker({
      // Consistent format with the HTML5 picker
      format: 'yyyy-mm-dd'
    });
  }

  render() {
    return (
      <div>
        <div className="card">
          <div className = "card-body">
            <label>Add New Grant</label>
            <AjaxForm name="grant" action={this.props.action} onError={this.onError.bind(this)} onSuccess={this.onSuccess.bind(this)}>
              {this.renderSelectPrograms()}
              {this.state.showDates &&
                this.renderDates()
              }
              <button type="submit" className="btn btn-primary pull-xs-right">Create New Grant</button>
              <div className="clearfix"></div>
            </AjaxForm>
          </div>
        </div>  
      </div>
    );
  }
}

export default AddGrant