import React from 'react'
import PropTypes from 'prop-types'

import Client from './client.jsx'

class Results extends React.Component {
    static propTypes = {
      searchResults: PropTypes.array,
      didSearch: PropTypes.bool,
    }

    componentDidMount() {
      $('[data-toggle="tooltip"]').tooltip()
    }

    render() {
      var prompt = `Can't find your ${window.clientLocale}?`;
      var noResultsPrompt = "Couldn't find anyone with that name. Create a new one.";
      var resultsPrompt = "Not one of the results? Create a new one.";
      //var button = <button className="btn btn-primary" data-placement="left" data-toggle="tooltip" title="Awaiting confirmation of client permission waiver" type="button">Create New Client</button>;
      var button = <a className="btn btn-primary" href="/dashboard/new_client">Create New {window.clientLocaleCapitalize}</a>;
      console.result
      var resultItems = this.props.searchResults.map(function(result) {
        return <Client key={result.id} ssn={result.ssn} fullName={result.full_name} birthdate={result.birthdate} gender={result.gender} clientUrl={'/dashboard/clients/' + result.id}  clientImage={result.image}/>;
      });
      if (this.props.didSearch === false) {
        resultItems.push(
          <div key="promptCase" className="card col-lg-3">
            <div className="card-body">
              <h4 className="card-title">{prompt}</h4>
              {button}
            </div>
          </div>
        );
      } else if (resultItems.length === 0) {
        resultItems.push(
          <div key="noResultPromptCase" className="card col-lg-3">
            <div className="card-body">
              <h4 className="card-title">{noResultsPrompt}</h4>
              {button}
            </div>
          </div>
        );
      } else {
        resultItems.push(
          <div key="resultsPromptCase" className="col-lg-3">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">{resultsPrompt}</h4>
                {button}
              </div>
            </div>
          </div>
        );
      }

      return(
        <div>
          <div className="container">
            <div className="row">
              {resultItems}
            </div>
          </div>
        </div>
      );
    }
}

export default Results
