import React from 'react'
import PropTypes from 'prop-types'

import Jstz from "jstimezonedetect"
import moment from 'moment-timezone'

class SubsurveyRow extends React.Component {
  static propTypes = {
    action: PropTypes.string,
    subsurvey: PropTypes.object,
    loadSubsurveys: PropTypes.func,
    toggleSelect: PropTypes.func
  }

  onClickSubsurvey(e) {
    e.preventDefault();
    this.props.toggleSelect(this.props.subsurvey);
  }

  makeSelectButton() {
    if (this.props.subsurvey.isSelected) {
      return <button key={"btn_"+this.props.subsurvey.isSelected} className="btn btn-info" onClick={this.onClickSubsurvey}><i className="far fa-check-square"></i></button>;
    } else {
      return <button key={"btn_"+this.props.subsurvey.isSelected} className="btn btn-success" onClick={this.onClickSubsurvey}><i className="far fa-square"></i></button>;
    }
  }

  displayDate(d) {
    var tz = Jstz.determine();

    if (d) {
      return moment(d).format('LLL');
    } else {
      return "N/A";
    }
  }

  onClickDelete(e) {
    e.preventDefault();

    if (confirm("Are you sure you want to delete the '"+this.props.subsurvey.assessment_name+"' subsurvey?")) {
      $.ajax({
        method: "DELETE",
        url: this.props.action+"/"+this.props.subsurvey.id,
        cache: false,
        context: this
      })
      .done(function(response) {
        this.props.loadSubsurveys();
      });
    }
  }

  makeDeleteButton() {
    if (this.props.subsurvey.can_delete) {
      return <button key={"btn_delete_"+this.props.subsurvey.id} className="btn btn-warning" onClick={this.onClickDelete}>
        <i className="fas fa-times"></i>
      </button>;
    }
  }

  getRowClass() {
    if (this.props.subsurvey.isSelected) {
      return "table-success";
    } else {
      return "";
    }
  }

  render() {
    return (
      <tr className={this.getRowClass()}>
        <th scope="row">{this.makeSelectButton()}</th>
        <td>
        { (this.props.subsurvey.show_link &&
          <a href={"/dashboard/client_assessments/"+this.props.subsurvey.id} target="_blank">
            {this.props.subsurvey.assessment_name}&nbsp;
            <i className="fas fa-external-link-alt"></i>
          </a>) ||
          <span>
            {this.props.subsurvey.assessment_name}
          </span>
        }
        </td>
        <td>{this.displayDate(this.props.subsurvey.created_at)}</td>
        <td>{(this.props.subsurvey.can_delete && this.makeDeleteButton())}
        </td>
      </tr>
    );
  }
}

export default SubsurveyRow
