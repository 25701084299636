import React from 'react'
import PropTypes from 'prop-types'

/*jshint esversion: 6 */

import autocomplete from "jquery-autocomplete"

class AutocompleteInput extends React.Component {
  static propTypes = {
    autocompleteOptions: PropTypes.object,
    name: PropTypes.string,
    id: PropTypes.string,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    class: PropTypes.string
  }

  constructor(props) {
    super(props)
    this.state = {
      query: this.props.value
    }
  }

  changeQuery(e) {
    this.setState({
      query: e.target.value
    });
  }

  componentDidMount() {
    if (!this.props.autocompleteOptions) {
      console.error("There's no autocomplete options. Remember to `include Nav` in the controller");
    }

    var list = Object.keys(this.props.autocompleteOptions).map(x => {
      return {
        text: x + " - " + this.props.autocompleteOptions[x]
      }
    });
    $("#"+this.props.id+"_autocomplete").autocomplete({
      list: list,
      matcher: function(typed) {
        if (!typed || typed.length === 0) {
          return undefined;
        }
        var reg = new RegExp("\\b" + typed, "i");
        reg.typed = typed;
        return reg;
      },
      match: function(element, matcher) {
          if (!matcher) {
            return false;
          }
          var typed = matcher.typed;
          element.typed = typed;
          element.pre_match = element.text;
          element.match = element.post_match = '';
          var match_at = element.text.search(matcher);
          if (match_at != -1) {
              element.pre_match = element.text.slice(0, match_at);
              element.match = element.text.slice(match_at, match_at + typed.length);
              element.post_match = element.text.slice(match_at + typed.length);
              return true;
          }
          return false;
      },
      insertText: function(obj) {
        return obj.text;
      }
    });

    $("#"+this.props.id+"_autocomplete").bind(
      "activated.autocomplete",
      this.changeQuery
    );
  }

  render() {
    var value = this.state.query.split(" - ")[0];
    return (
      <div>
        <input type={this.props.type} name={this.props.name+"_autocomplete"} id={this.props.id+"_autocomplete"} value={this.state.query} placeholder={this.props.placeholder} className={this.props.class} onInput={this.changeQuery.bind(this)} />
        <input type="hidden" name={this.props.name} id={this.props.id} value={value} />
      </div>
  );
  }
}

export default AutocompleteInput
