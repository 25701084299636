import React from 'react'
import PropTypes from 'prop-types'

import AddMedia from "./add_media.js.jsx"
import AjaxForm from "./ajax_form.js.jsx"

class MediaGallery extends React.Component {
  static propTypes = {
    action: PropTypes.string
  }

  constructor(props) {
    super(props)
    this.state = {
      media: []
    }
  }

  componentDidMount() {
    this.loadMedia();

    // initialize lightbox after ajax call
    if (!window.lightbox) {
      window.lightbox = require("lightbox2");
    }
  }

  loadMedia() {
    $.ajax({
      method: "GET",
       url: this.props.action,
       cache: false,
       context: this
     })
     .done(function(response) {
       this.setState({
         media: response.media
       });
     });
  }

  onSuccess(response) {
    this.setState({
      media: response.media
    });
  }

  renderMedia() {
    var media = [];
    var mediaSlice;
    var size = 3;
    var renderMedium = function(medium, index) {
      if(medium['file'].substring(medium['file'].lastIndexOf(".")).includes("pdf")) {
        return (
          <div key={medium['file']} className="col-lg-4">
            <div className="card">
              <a href={medium['file']}>{medium['filename']}</a>
            </div>
            <br/>
          </div>
        )
      } else {
        var imgClass = medium.rotation === undefined ? "card-img-top" : "card-img-top "+medium.rotation;
        return (
          <div key={medium['file']} className="col-lg-4">
            <div className="card card-contain">
              <a href={medium['file']} data-lightbox="image-gallery">
                <img src={medium['file']} className={imgClass} />
              </a>
            </div>
            <br/>
            {(medium.rotation !== undefined)
              && (
                <div className="rotate-button">
                  <AjaxForm name={"rotate_"+medium['file']} action={this.props.action+'/'+medium.id} method="POST" onSuccess={this.loadMedia.bind(this)}>
                    <input type='hidden' name='_method' value='put'/>
                    <button type="submit" className={"btn btn-info"}>
                      <i className="fas fa-redo"/> Rotate
                    </button>
                  </AjaxForm>
                  <AjaxForm name={"delete_"+medium['file']} action={this.props.action+'/'+medium.id} method="DELETE" onSuccess={this.loadMedia.bind(this)} confirmMessage={"Are you sure you want to delete this media and any data associated with it?"}>
                    <input type="hidden" name="_method" value="delete" />
                    <button type="submit" className={"btn btn-danger"}>
                      <i className="far fa-trash-alt"/> Delete
                    </button>
                  </AjaxForm>
                </div>
              )
            }
            <br/>
          </div>
        );
      }
    }.bind(this);

    for (var i = 0; i < this.state.media.length; i += size) {
      mediaSlice = this.state.media.slice(i, i + size);
      media.push(
        <div className="card-group" key={"mediaSlice_"+i}>
          {mediaSlice.map(renderMedium)}
        </div>
      );
    }

    return media;
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-lg-12">
            {this.renderMedia()}
          </div>
        </div>
        <AddMedia action={this.props.action} onSuccess={this.onSuccess.bind(this)} />
      </div>
    );
  }
}

export default MediaGallery
