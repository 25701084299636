import React from 'react'
import PropTypes from 'prop-types'

import AjaxForm from "./ajax_form.js.jsx"
import AddGrant from "./add_grant.jsx"
import SelectGrant from "./select_grant.jsx"

class Grants extends React.Component {
  static propTypes = {
    action: PropTypes.string,
    programs: PropTypes.array
  }

  loadGrants() {
    this.refs.selectGrant.loadGrants();
  }

  render() {
    return (
      <fieldset className="form-group">
        <label>Grants</label>
        <p>Let us know which grants are associated to this project or indicate that none of the grants apply.</p>
        <div className="card">
          <div className="card-block">
            <label>Select Grants</label>
            <SelectGrant ref="selectGrant" action={this.props.action} />
            <hr/>
            <AddGrant action={this.props.action} loadGrants={this.loadGrants.bind(this)} programs={this.props.programs} />
          </div>
        </div>
      </fieldset>
    );
  }
}

export default Grants
