import React from 'react'
import PropTypes from 'prop-types'

import AjaxForm from "./ajax_form.js.jsx"
import Humanize from "humanize-plus"

class ActionItemForm extends React.Component {
  static propTypes = {
    actionableId: PropTypes.number,
    taggableUsers: PropTypes.array,
    userId: PropTypes.number,
    name: PropTypes.string,
    action: PropTypes.string,
    inputName: PropTypes.string
  }

  componentDidMount() {
    var taggableUsers = this.props.taggableUsers;
    $("#demo-input").tokenInput(taggableUsers);

    // If the browser supports HTML5 date pickers & is a touch device, use it
    // otherwise, fallback to jquery-ui for a consistent ux
    var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    if (!iOS || !Modernizr.inputtypes.date) {
      $('input[type=date]')
        .attr('type', 'text')
        .datepicker({
          // Consistent format with the HTML5 picker
          format: 'yyyy-mm-dd',
          zIndex: 2000
        });
    }
  }

  renderActionItemTaggedUsers(taggedUsers) {
    var renderedTaggedUsers;
    if (taggedUsers.length === 0) {
      renderedTaggedUsers =
        <div className="card-footer text-muted">
          <strong>Tagged:</strong> None.
        </div>;
    } else {
      var taggedUserEmails = [];
      for (var i = 0; i < taggedUsers.length; i++) {
        var taggedUser = taggedUsers[i];
        taggedUserEmails.push(
          taggedUser['full_name_and_agency']
        );
      }
      renderedTaggedUsers =
        <div className="card-footer text-muted">
          <strong>Tagged:</strong> {taggedUserEmails.join(", ")}
        </div>;
    }
    return renderedTaggedUsers;
  }

  renderActionItemForm () {
    return (
      <AjaxForm name={this.props.name} action={this.props.action} onSuccess={() => {window.location.reload(true);}} className="disable-after-click" method="post">
        <div>
          <input type="hidden" name={this.props.name + "[user_id]"} value={this.props.userId}></input>
          <input type="hidden" name={this.props.inputName} value={this.props.actionableId}></input>
        </div>
        <div className="card" style={{padding: '15px'}}>
          <div className="form-group">
            <h5><label htmlFor="title">Create New Referral</label></h5>
            <fieldset className="form-group">
              <h6><label>Tag users or groups</label></h6>
              <input type="text" className="form-control" id="demo-input" name={this.props.name + "[names]"}>
              </input>
            </fieldset>
            <hr />
            <fieldset className="form-group">
              <input type="text" className="form-control" id="description" name={this.props.name + "[title]"} placeholder="Enter title" required={true}>
              </input>
            </fieldset>
            <fieldset className="form-group">
              <textarea className="form-control" id="description" name={this.props.name + "[description]"} placeholder="Enter description" required={true}>
              </textarea>
            </fieldset>
            <fieldset className="form-group">
              <h6><label>Due date</label></h6>
              <input type="date" className="form-control" name={this.props.name + "[due_date]"}>
              </input>
            </fieldset>
            <fieldset className="form-group">
              <button type="submit" className="btn btn-gradient-primary btn-round btn-block waves-effect waves-light">Submit</button>
            </fieldset>
          </div>
        </div>
    </AjaxForm>
    );
  }

  render() {
    return (
      <div>
        {this.renderActionItemForm()}
      </div>
    );
  }
}

export default ActionItemForm