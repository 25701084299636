import React from 'react'
import PropTypes from 'prop-types'

class Racecheck extends React.Component {
  static propTypes = {
    checkBoxTags: PropTypes.array,
    questionAnswerTexts: PropTypes.array,
  }

  componentDidMount() {
    var that = this;
    $("div.racecheck-form-group .radio-check input[type=checkbox]").change(function() {
      if ($(this).prop('checked')) {
        that.resetRaceChecks(this);
      }
    });

    $("div.racecheck-form-group .normal-check input[type=checkbox]").change(function() {
      that.resetExplanationChecks(this);
    });
  }

  resetRaceChecks(clicked) {
    $("div.racecheck-form-group input[type=checkbox]").each(function() {
      $(this).prop('checked', false);
    });
    $(clicked).prop('checked', true);
  }

  resetExplanationChecks(clicked) {
    $("div.racecheck-form-group .radio-check input[type=checkbox]").each(function() {
      $(this).prop('checked', false);
    });
  }

  renderInnerHTML(checkBoxTag, questionAnswerText) {
    return {__html: checkBoxTag};
  }

  renderCheckBoxes() {
    var numOptions = this.props.checkBoxTags.length;

    return this.props.checkBoxTags.map(function(checkBoxTag, i) {
      // NOTE: The last three options are explanations and therefore require special logic to handle
      var isLastThreeOptions = i >= numOptions - 3;

      return (
        <div key={"checkboxtag-" + i} className={(isLastThreeOptions) ? "form-check radio-check" : "form-check normal-check"}>
          <label className="form-check-label">
            <span dangerouslySetInnerHTML={this.renderInnerHTML(checkBoxTag)} />
            &nbsp;
            {this.props.questionAnswerTexts[i]}
          </label>
        </div>
      );
    }.bind(this));
  }

  render() {
    return (
      <div className="form-group racecheck-form-group">
        {this.renderCheckBoxes()}
      </div>
    );
  }
}

export default Racecheck
