import React from 'react'
import PropTypes from 'prop-types'

import AjaxForm from "./ajax_form.js.jsx"
import Comments from "./comments.jsx"

class RenderCampCommentForm extends React.Component {
  static propTypes = {
    userId: PropTypes.number,
    campId: PropTypes.number,
    createCommentUrl: PropTypes.string
  }

  render() {
    return(
      <div>
        <Comments name="camp_comment" action={this.props.createCommentUrl} type="camp">
          <input type="hidden" name="camp_comment[user_id]" value={this.props.userId}></input>
          <input type="hidden" name="camp_comment[camp_id]" value={this.props.campId}></input>
        </Comments>
      </div>
    )
  }
}

export default RenderCampCommentForm