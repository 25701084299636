import React from 'react'
import PropTypes from 'prop-types'

import Humanize from "humanize-plus"

class TokenInput extends React.Component {
  static propTypes = {
    taggable: PropTypes.array,
    fieldName: PropTypes.string
  }

  componentDidMount() {
    var taggable = this.props.taggable;
    $("#token-input").tokenInput(taggable);
  }

  render() {
    return(
      <div className="from-group">
        <input type="text" className="form-control" id="token-input" name={this.props.fieldName}>
        </input>
      </div>
    )
  }
}

export default TokenInput
