import React from 'react'
import PropTypes from 'prop-types'
import Jstz from 'jstimezonedetect'
import moment from 'moment-timezone'

class TimeString extends React.Component {
  static propTypes = {
    prefixString: PropTypes.string,
    timeString: PropTypes.number,
    formatString: PropTypes.string,
    tzName: PropTypes.string
  }

  constructor(props) {
    super(props)
    this.state = {
      prefixString: "",
      formatString: 'YYYY-MM-DD HH:mm',
      tzName: Jstz.determine().name()
    }
  }

  render() {
    return (
      <div>
        {this.props.prefixString}
        {moment.tz(new Date(this.props.timeString), this.state.tzName).format(this.state.formatString)}
      </div>
    )
  }
}

export default TimeString
