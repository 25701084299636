import React from 'react'
import PropTypes from 'prop-types'

import SignaturePad from 'signature_pad'

class NewClientForm extends React.Component {
  static propTypes = {
    clientPermissionWaivers: PropTypes.array,
  }

  constructor(props) {
    super(props)
    this.state = {
      canSubmit: false,
      signatureImage: ""
    }
  }

  componentDidMount() {
    var wrapper = document.getElementById("signature-pad"),
      clearButton = wrapper.querySelector("[data-action=clear]"),
      saveButton = wrapper.querySelector("[data-action=save]"),
      canvas = wrapper.querySelector("canvas"),
      signaturePad;

    // Adjust canvas coordinate space taking into account pixel ratio,
    // to make it look crisp on mobile devices.
    // This also causes canvas to be cleared.

    window.onresize = () => {
        // When zoomed out to less than 100%, for some very strange reason,
        // some browsers report devicePixelRatio as less than 1
        // and only part of the canvas is cleared then.
        var ratio =  Math.max(window.devicePixelRatio || 1, 1);
        canvas.width = canvas.offsetWidth * ratio;
        canvas.height = canvas.offsetHeight * ratio;
        canvas.getContext("2d").scale(ratio, ratio);
    }
    window.onresize()

    this.signaturePad = new SignaturePad(canvas, {
      penColor: "#000"
    });
  }

  clearSignature() {
    this.setState({
      canSubmit: false
    }, function() {
      this.signaturePad.clear();
      $("#signature-image").val('');
    }.bind(this));
  }

  generateSignature() {
    if (this.signaturePad.isEmpty()) {
      alert("Please provide signature first.");
    } else {
      this.setState({
        canSubmit: true,
        signatureImage: this.signaturePad.toDataURL('image/png')
      })
    }
  }

  generateTranslationNav(id, translationJson) {
    var navArray = [];
    if(translationJson != null) {
      Object.keys(translationJson).forEach(function(key) {
        navArray.push(<li className="nav-item">
          <a className="nav-link" data-toggle="tab" href={"#" + key + id}>{key}</a>
        </li>)
      })
    }

    return navArray;
  }

  generateTranslationTabContent(id, translationJson) {
    var tabContentArray = [];
    if(translationJson != null) {
      Object.keys(translationJson).forEach(function(key) {
        var value = translationJson[key]
        tabContentArray.push(
          <div className="tab-pane" id={key + id} role="tabpanel">
            <div className="card-body jumbotron side-padding" dangerouslySetInnerHTML={{__html: value}}>
            </div>
          </div>
        )
      })
    }
    return tabContentArray;
  }

  generatePrivacyPolicy() {
    var cardArray = [];
    this.props.clientPermissionWaivers.forEach(function(cpw) {
      var navArray = this.generateTranslationNav(cpw.id, cpw.translations);
      var navTabContent = this.generateTranslationTabContent(cpw.id, cpw.translations);
      var headingId = "heading" + cpw.id
      var collapseId = "collapse" + cpw.id

      cardArray.push(
        <div key={cpw.id}>
          <input id={"client_permission_waiver_" + cpw.id} name={"client_permission_waiver_" + cpw.id} type="checkbox" value="true" />
          &emsp;
          <label htmlFor={"client_permission_waiver_" + cpw.id}>I agree to the contents of this waiver.</label>
          <br></br>
          <div className="card">
            <div className="card-header" id={headingId}>
              <h5 className="mb-0">
                <button type="button" className="btn btn-link collapsed" data-toggle="collapse" data-target={"#" + collapseId} aria-expanded="false" aria-controls={collapseId}>
                  {cpw.title}
                </button>
              </h5>
            </div>
            <div id={collapseId} className="collapse" aria-labelledby={headingId} data-parent="#accordion">
              <ul className="nav nav-pills" role="tablist">
                <li className="nav-item">
                  <a className="nav-link active" data-toggle="tab" style={{'marginLeft': '10px'}} href={"#en" + cpw.id}>EN</a>
                </li>
                {navArray}
              </ul>
              <div className="tab-content">
                <div className="tab-pane active" id={"en"+cpw.id} role="tabpanel">
                  <div className="card-body jumbotron side-padding" dangerouslySetInnerHTML={{__html: cpw.text}}>
                  </div>
                </div>
                {navTabContent}
              </div>
            </div>
          </div>
        </div>
      )
    }.bind(this));
    return cardArray;
  }

  render() {
    return (
      <div>
        <h5 className="roboto">Release of Information/Permission Waivers</h5>
        <p></p>
        <div id="accordion">
          {this.generatePrivacyPolicy()}
        </div>
        <strong className="roboto">By signing, I give authorization for mine and my dependents listed above Protected Personal and relevant information to be entered into Outreach Grid.</strong>
        <p></p>
        <input type='hidden' id="signature-image" name="signature-image" value={this.state.signatureImage}></input>
        <div id="signature-pad" className="m-signature-pad">
          <div className="m-signature-pad--body">
            <canvas id='signature-pad' className='signature-pad' height='400' style={{width: '100%'}}></canvas>
          </div>
          <hr></hr>
          <div className="m-signature-pad--footer">
            <div className="float-left">
              <button className="button clear btn btn-warning" data-action="clear" type="button" onClick={this.clearSignature.bind(this)}> Clear</button>
              &nbsp;
              <button className="button save btn btn-info" data-action="save" type="button" onClick={this.generateSignature.bind(this)}> Accept Signature</button>
            </div>
            <div className="float-right">
              {this.state.canSubmit &&
                <button id='signature-submit' className='btn btn-primary'>Submit</button>
              }
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
      </div>
    );
  }
};

export default NewClientForm