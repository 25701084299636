import React from 'react'
import PropTypes from 'prop-types'

import Jstz from "jstimezonedetect"
import moment from 'moment-timezone'

class GrantRow extends React.Component {
  static propTypes = {
    action: PropTypes.string,
    grant: PropTypes.object,
    loadGrants: PropTypes.func,
    toggleSelect: PropTypes.func
  }

  onClickGrant(e) {
    e.preventDefault();
    this.props.toggleSelect(this.props.grant);
  }

  makeSelectButton() {
    if (this.props.grant.isSelected) {
      return <button key={"btn_"+this.props.grant.isSelected} className="btn btn-info" onClick={this.onClickGrant.bind(this)}><i className="far fa-check-square"></i></button>;
    } else {
      return <button key={"btn_"+this.props.grant.isSelected} className="btn btn-success" onClick={this.onClickGrant.bind(this)}><i className="far fa-square"></i></button>;
    }
  }

  displayDate(d) {
    var tz = Jstz.determine();

    if (d) {
      return moment.tz(d, "utc").format('LL');
    } else {
      return "N/A";
    }
  }

  onClickDelete(e) {
    e.preventDefault();

    if (confirm("Are you sure you want to delete the '"+this.props.grant.program.name+"' grant?")) {
      $.ajax({
        method: "DELETE",
        url: this.props.action+"/"+this.props.grant.id,
        cache: false,
        context: this
      })
      .done(function(response) {
        this.props.loadGrants();
      });
    }
  }

  makeDeleteButton() {
    if (this.props.grant.can_delete) {
      return <button key={"btn_delete_"+this.props.grant.id} className="btn btn-warning" onClick={this.onClickDelete}>
        <i className="fas fa-times"></i>
      </button>;
    }
  }

  getRowClass() {
    if (this.props.grant.isSelected) {
      return "table-success";
    } else {
      return "";
    }
  }

  render() {
    return (
      <tr className={this.getRowClass()}>
        <th scope="row">{this.makeSelectButton()}</th>
        <td>{this.props.grant.program.name}</td>
        <td>{this.displayDate(this.props.grant.start_at)}</td>
        <td>{this.displayDate(this.props.grant.end_at)}</td>
        <td>{(this.props.grant.can_delete && this.makeDeleteButton())}
        </td>
      </tr>
    );
  }
}

export default GrantRow