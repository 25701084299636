import React from 'react'
import PropTypes from 'prop-types'
import SignaturePad from 'signature_pad'

class SignatureForm extends React.Component {
  static propTypes = {
    hasSubmit: PropTypes.bool,
    signatureName: PropTypes.string
  }

  constructor(props) {
    super(props)
    var submittable;
    if(this.props.hasSubmit == undefined) {
      submittable = true
    } else {
      submittable = false
    }

    var sigName;
    if(this.props.signatureName == undefined) {
      sigName = "signature-image"
    } else {
      sigName = this.props.signatureName
    }
  
    this.state = {
      signatureName: sigName,
      hasSubmit: submittable,
      canSubmit: false,
      signatureImage: ""
    };
  }

  componentDidMount() {
    var wrapper = document.getElementById("signature-pad"),
      clearButton = wrapper.querySelector("[data-action=clear]"),
      saveButton = wrapper.querySelector("[data-action=save]"),
      canvas = wrapper.querySelector("canvas"),
      signaturePad;

    // Adjust canvas coordinate space taking into account pixel ratio,
    // to make it look crisp on mobile devices.
    // This also causes canvas to be cleared.
    window.onresize = () => {
      // When zoomed out to less than 100%, for some very strange reason,
      // some browsers report devicePixelRatio as less than 1
      // and only part of the canvas is cleared then.
      var ratio =  Math.max(window.devicePixelRatio || 1, 1);
      canvas.width = canvas.offsetWidth * ratio;
      canvas.height = canvas.offsetHeight * ratio;
      canvas.getContext("2d").scale(ratio, ratio);
    }
    window.onresize()

    this.signaturePad = new SignaturePad(canvas, {
      penColor: "#000"
    });
  }

  clearSignature() {
    this.setState({
      canSubmit: false
    }, function() {
      this.signaturePad.clear();
      $("#signature-image").val('');
    }.bind(this));
  }

  generateSignature() {
    if (this.signaturePad.isEmpty()) {
      alert("Please provide signature first.");
    } else {
      this.setState({
        canSubmit: true,
        signatureImage: this.signaturePad.toDataURL('image/png')
      });
    }
  }

  render() {
    return (
      <div>
        <input type='hidden' id="signature-image" name='signature-image' value={this.state.signatureImage}></input>
        <p className="roboto">Signature</p>
        <div id="signature-pad" className="m-signature-pad">
          <div className="m-signature-pad--body">
            <canvas id='signature-pad' className='signature-pad' height='400' style={{width: '100%'}}></canvas>
          </div>
          <hr></hr>
          <div className="m-signature-pad--footer">
            <div className="pull-xs-left">
              <button className="button clear btn btn-warning" data-action="clear" type="button" onClick={this.clearSignature.bind(this)}> Clear</button>
              &nbsp;
              <button className="button save btn btn-info" data-action="save" type="button" onClick={this.generateSignature.bind(this)}> Accept Signature</button>
            </div>
            <div className="pull-xs-right">
              {this.state.canSubmit && this.state.hasSubmit &&
                <button id='signature-submit' className='btn btn-primary'>Submit</button>
              }
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default SignatureForm