import React from 'react'
import PropTypes from 'prop-types'

import AjaxForm from "./ajax_form.js.jsx"
import SelectSubsurvey from "./select_subsurvey.jsx"

class Subsurveys extends React.Component {
  static propTypes = {
    action: PropTypes.string,
  }

  loadSubsurveys() {
    this.refs.selectSubsurvey.loadSubsurveys();
  }

  render() {
    return (
      <fieldset className="form-group">
        <h5>SUBSURVEYS</h5>
        <p>Enter information for others in your household.</p>
        <div className="card">
          <div className="card-block">
            <label>Select Subsurveys</label>
            <SelectSubsurvey ref="selectSubsurvey" action={this.props.action+'/subsurveys'} />
            <hr/>
          </div>
        </div>
      </fieldset>
    );
  }
}

export default Subsurveys