import React from 'react'
import PropTypes from 'prop-types'

import AjaxForm from "./ajax_form.js.jsx"

class AddTag extends React.Component {
  static propTypes = {
    updateUrl: PropTypes.string,
    taggableTypes: PropTypes.array,
    currentTags: PropTypes.array,
    onlyInput: PropTypes.bool
  }

  componentDidMount() {
    $("#tag-input-pre-populated").tokenInput(this.props.taggableTypes, {
      prePopulate: this.props.currentTags
    });
  }

  optionTags() {
    return (
      <AjaxForm name="bla" action={this.props.updateUrl} method="post" >
        <input type="text" className="form-control" id="tag-input-pre-populated" name="tag_id" />
        <br></br>
        <div className="pull-xs-right">
          <button type="submit" value="Submit" className="btn btn-success">Update</button>
        </div>
      </AjaxForm>
    );
  }

  optionInput() {
    return (
      <input type="text" className="form-control" id="tag-input-pre-populated" name="tag_id" />
    );
  }


  render() {
    if (this.props.onlyInput) {
      return (
        <div>
          {this.optionInput()}
        </div>
      )
    } else {
      return (
        <div>
          {this.optionTags()}
        </div>
      )
    }
  }

}

export default AddTag
