import React from 'react'
import PropTypes from 'prop-types'

import Dropzone from "dropzone"

class AddMedia extends React.Component {
  static propTypes = {
    action: PropTypes.string,
    onSuccess: PropTypes.func
  }

  componentDidMount() {
    Dropzone.autoDiscover = false;
    this.dropzone = new Dropzone(".dropzone", {
      url: this.props.action,
      addRemoveLinks: true,
      context: this,
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      success: this.onSuccess,
      error (file, response) {
        file.previewElement.classList.add("dz-error");
      }
    });

    this.dropzone.on("removedfile", this.onRemovedFile);
  }

  componentDidUpdate() {
  }

  onSuccess (file, response) {
    file.previewElement.classList.add("dz-success");

    this.props.onSuccess(response);
  }

  onDeleteSuccess (response) {
    this.props.onSuccess(response);
  }

  onRemovedFile(file) {
    var response = JSON.parse(file.xhr.response);
    var newMedia = response.newMedia;
    var mediaId = newMedia.id;

    $.ajax({
      method: "DELETE",
      url: this.props.action + "/" + mediaId,
      success: this.onDeleteSuccess
    });
  }

  render() {
    return (
      <div className="add-media">
        <h4>Add Media</h4>
        <div className="dropzone">
          <div className="dz-default dz-message">
            <h5>Drop files here or click to upload.</h5>
          </div>
        </div>
      </div>
    );
  }
}

export default AddMedia