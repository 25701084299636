import React from 'react'
import PropTypes from 'prop-types'

import AjaxForm from "./ajax_form.js.jsx"
import Jstz from "jstimezonedetect"
import moment from 'moment-timezone'

class Comments extends React.Component {
  static propTypes = {
    clientId: PropTypes.number,
    userId: PropTypes.number,
    name: PropTypes.string,
    action: PropTypes.string
  }

  constructor(props) {
    super(props)
    this.state = {
      comments: []
    };
  }

  componentDidMount() {
    this.loadComments();
  }

  loadComments() {
    $.ajax({
      method: "GET",
       url: this.props.action,
       cache: false,
       context: this
     })
     .done(function(response) {
       this.setState({
         comments: response.comments
       });
     });
  }

  onSuccess(response) {
    this.setState({
      comments: response.comments
    });
  }

  renderComments() {
    var tz = Jstz.determine();

    return this.state.comments.map(function(comment, index) {
      return (
        <div className="card" key={index}>
          <div className="card-body">
            <div className="float-left">
              <img src={comment.user_image} width="20px" />
              &emsp;
              <strong>{comment.full_name_and_agency}</strong>
            </div>
            <div className="float-right">
              <div className="text-muted">
                <small>{moment.tz(comment.created_at, tz.name()).format('LLLL')}</small>
              </div>
            </div>
            <div className="clearfix"></div>
            <p></p>
            &emsp;
            <p></p>
            {comment.comment}
          </div>
        </div>
      );
    });
  }

  renderCommentForm () {
    return (
      <AjaxForm name={this.props.name} action={this.props.action} onSuccess={this.onSuccess.bind(this)}>
        <div className="form-group">
          {this.props.children ||
            <div>
            <input type="hidden" name="client_comment[user_id]" value={this.props.userId}></input>
            <input type="hidden" name="client_comment[client_id]" value={this.props.clientId}></input>
            </div>
          }
          <fieldset className="form-group">
            <h5><label htmlFor="comment">Add New Comment</label></h5>
            <textarea className="form-control" id="comment" name={this.props.name + "[comment]"} tabIndex="100">
            </textarea>
          </fieldset>

          <fieldset className="form-group">
            <button type="submit" className="btn btn-gradient-success" tabIndex="101">Submit</button>
          </fieldset>
        </div>
      </AjaxForm>
    );
  }

  render() {
    return (
      <div>
        <h5 className="roboto">Comments</h5>
        <div className="text-muted roboto">
          Comments are part of a {window.clientLocale}'s public profile. Other users in the Outreach Grid Network will have access to them.
        </div>
        <hr></hr>
        {this.renderComments()}
        <hr></hr>
        {this.renderCommentForm()}
      </div>
    );
  }
}

export default Comments