import React from 'react'
import ReactDOM from 'react-dom'

class SearchBox extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        query: ''
      }
    }

    render() {
      return (
        <div>
          <div className="card bg-info" style={{'color': 'white', 'borderRadius': '0px'}}>
            <div className="card-body">
              <p className="roboto">
                Enter the full name of the new {window.clientLocale}
              </p>
              <fieldset className="form-group">
                <input type="text" value={this.state.query} onChange={evt => this.updateInputValue(evt)} className="form-control" placeholder="John Smith" onKeyPress={this.handleChange.bind(this)}/>
                <p></p>
                <input type="submit" onClick={this.createAjax.bind(this)} className="btn btn-secondary" value="Search"/>
              </fieldset>
            </div>
          </div>
        </div>
      );
    }

    updateInputValue(evt) {
      this.setState({
        query: evt.target.value
      });
    }

    handleChange(e) {
      if (e.key == 'Enter') {
        this.createAjax();
      }
    }

    createAjax() {
      var query    = this.state.query
      var URL      = '/dashboard/client/search?query=' + query;
      this.props.search(URL);
    }
}

export default SearchBox