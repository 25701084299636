import React from 'react'
import PropTypes from 'prop-types'

/*jshint esversion: 6 */

import Jstz from "jstimezonedetect"
import moment from 'moment-timezone'
import GrantRow from "./grant_row.js.jsx"

class SelectGrant extends React.Component {
  static propTypes = {
    action: PropTypes.string
  }

  constructor(props) {
    super(props)
    this.state = {
      grants: []
    }
  }

  componentDidMount() {
    this.loadGrants();
  }

  loadGrants() {
    $.ajax({
      method: "GET",
      url: this.props.action,
      cache: false,
      context: this
    })
    .done(function(response) {
      var grants = response.grants.map(grant => {
        grant.isSelected = grant.is_na;
        return grant;
      });

      this.setState({
        grants: grants
      });
    });
  }

  toggleSelect(toggledGrant) {
    var grants;

    if (toggledGrant.is_na) {
      grants = this.state.grants.map(grant => {
        grant.isSelected = grant.is_na;
        return grant;
      });
    } else {
      grants = this.state.grants.map(grant => {
        if (toggledGrant.id === grant.id) {
          grant.isSelected = !grant.isSelected;
        } else if (grant.is_na) {
          grant.isSelected = false;
        }
        return grant;
      });
    }

    var numberSelected = grants.reduce((accumulator, grant) => {
      var add = grant.isSelected ? 1 : 0;
      return accumulator + add;
    }, 0);

    if (numberSelected === 0) {
      grants = this.state.grants.map(grant => {
        grant.isSelected = grant.is_na;
        return grant;
      });
    }

    this.setState({
      grants: grants
    });
  }

  render() {
    return (
      <table className="table table-hover table-responsive-lg">
        <thead>
          <tr>
            <th scope="col"><i className="far fa-2x fa-plus-square"></i></th>
            <th scope="col">Program Name</th>
            <th scope="col"><i className="far fa-2x fa-calendar"></i></th>
            <th scope="col"><i className="far fa-2x fa-calendar-check"></i></th>
            <th scope="col"><i className="far fa-2x fa-trash-alt"></i></th>
          </tr>
        </thead>
        <tbody>
          {this.state.grants.map((grant) =>
            <GrantRow action={this.props.action} grant={grant} loadGrants={this.loadGrants} toggleSelect={this.toggleSelect.bind(this)} key={"grant_"+grant.id}/>
          )}
          {this.state.grants.filter((grant) => grant.isSelected)
            .map((grant) => <input type="hidden" name="grant_ids[]" value={grant.id} key={"grant_selected_"+grant.id} />
          )}
        </tbody>
      </table>
    );
  }
}

export default SelectGrant