import React from 'react'

import SearchBox from './search_box.js'
import Results from './results.js'
// var SearchBox = require("./search_box.js.jsx")
// var Results = require("./results.js.jsx")

class ClientSearch extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        didSearch: false,
        searchResults: []
      };
    }

    showResults(response) {
      console.log(response);
      this.setState({
        didSearch: true,
        searchResults: response
      })
    }

    search(URL) {
      console.log(`URL ${URL}`)
      $.ajax({
        type: "GET",
        context: this,
        dataType: 'json',
        url: URL,
        success: (response) => {
          this.showResults(response)
        }
      })
    }

    render() {
      return (
        <div>
          <SearchBox search={this.search.bind(this)} />
          <Results searchResults={this.state.searchResults} didSearch={this.state.didSearch} />
        </div>
      )
    }


}

export default ClientSearch
