import React from 'react'
import PropTypes from 'prop-types'

import Humanize from 'humanize-plus'
import L from 'leaflet'
import "drmonty-leaflet-awesome-markers"
import "leaflet.locatecontrol"
import "leaflet-control-geocoder"

// Allows us to geocode first with w3w, and secondly with open street map (nominatim)

class MapReport extends React.Component {
  static propTypes = {
    data: PropTypes.array,
    id: PropTypes.string
  }

  constructor(props) {
    super(props)
    this.state = {
      data: []
    }
  }

  getMap() {
    if (!this.map) {
      this.map = L.map(this.props.id);
    }
    return this.map;
  }

  getLocateControl() {
    if (!this.locateControl) {
      var map = this.getMap();
      this.locateControl = L.control.locate({
        icon: "fa fa-location-arrow",
        watch: true
      }).addTo(map);
    }
    return this.locateControl;
  }

  componentDidMount() {
    var CombiGeocoder = {
      w3w: new L.Control.Geocoder.What3Words("ZCWG07FV"),
      w3wRe: new RegExp(/^\w{4,}\.\w{4,}\.\w{1,}$/),
      nom: new L.Control.Geocoder.nominatim(),
    
      geocode(query, callback, context) {
        var results;
        var isW3wQuery = this.w3wRe.test(query);
    
        if (isW3wQuery) {
          results = this.w3w.geocode(query, callback, context);
        } else {
          results = this.nom.geocode(query, callback, context);
        }
    
        return results;
      },
    
      reverse(location, scale, callback, context) {
        var results;
        var isW3wQuery = this.w3wRe.test(query);
    
        if (isW3wQuery) {
          results = this.w3w.reverse(location, scale, callback, context);
        } else {
          results = this.nom.reverse(location, scale, callback, context);
        }
    
        return results;
      }
    };
    
    var map = this.getMap();
    map.setView([window.default_lat, window.default_lng], 14);

    // fix for being inside a bootstrap tab that resizes after it loads
    $('a[data-toggle="tab"]').on('click', function() {
      setTimeout(function(){ map.invalidateSize()}, 500);
    });

    // specify the path to the leaflet images folder
    L.Icon.Default.imagePath = '/images/';
    this.getLocateControl();

    L.tileLayer('https://api.mapbox.com/v4/{map_id}/{z}/{x}/{y}.png?access_token={accessToken}', {
      attribution: 'Map data &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="http://mapbox.com">Mapbox</a>',
      maxZoom: 18,
      map_id: 'mapbox.light',
      id: 'appledore.0ajdb977',
      accessToken: 'pk.eyJ1IjoiYXBwbGVkb3JlIiwiYSI6ImNpcDI4bHl2dDAwMHV0c20wczlrMGN3cHEifQ.ZTVATzrND2lnLvC9GCX5zw'
    }).addTo(map);

    L.Control.geocoder({
      geocoder: Object.create(CombiGeocoder)
    }).addTo(map);

    var goHomeControl = L.Control.extend({
       options: {
         position: 'topleft'
       },
       onAdd: this.onAddGoHome,
     });
    map.addControl(new goHomeControl());

    this.loadData();
  }

  loadData() {
    this.setState({data: this.props.data});
  }

  addDataToMap(data) {
    var point;
    var marker;
    var thumbnail;
    var map = this.getMap();
    var lat;
    var lng;
    var tag;
    var color;
    var description;
    var name;
    var url;

    for (var i = 0; i < data.length; i++) {
      point = data[i];
      lat = point['lat'];
      lng = point['lng'];
      tag = point['tag'];
      color = point['color']
      description = point['description'];
      name = point['name'];
      url = point['url'];

      if (lat && lng) {
        var markerColor = color || 'beige';
        var awesomeMarker = L.AwesomeMarkers.icon({
          markerColor: markerColor
        });
        marker = L.marker([lat, lng], {icon: awesomeMarker}).addTo(map);

        var humanizedName = Humanize.capitalize(name);

        if (typeof url != "undefined") {
          marker.bindPopup("<span class='label label-primary'>"+tag+"</span>&nbsp;<a href='"+url+"'><strong>"+humanizedName+"</strong></a><br>"+description);
        }
        else {
          marker.bindPopup("<span class='label label-primary'>"+tag+"</span>&nbsp;<strong>"+humanizedName+"</strong><br>"+description);
        }
      }
    }
  }


  componentDidUpdate(_, prevState) {
    // Only update if the camps change
    // This prevents it from messing with CreateCamp updates
    if (prevState.data !== this.state.data) {
      this.addDataToMap(this.state.data);

      // Center on the last camp's location
      if (this.state.data.length) {
        var map = this.getMap();
        var lastData = this.state.data[this.state.data.length - 1];
        map.setView([lastData['lat'], lastData['lng']], 14);
      }
    }
  }

  onAddGoHome(map) {
    var container = L.DomUtil.create('div', 'leaflet-control-locate leaflet-bar leaflet-control');
    var controlUI = L.DomUtil.create('a', 'leaflet-bar-part leaflet-bar-part-single', container);
    this.goHomeIcon = L.DomUtil.create('span', 'fa fa-home', controlUI);

    container.onclick = this.onClickGoHome;
    return container;
  }

  onClickGoHome(e) {
    var map = this.getMap();
    map.setView([window.default_lat, window.default_lng], 14);
  }


  render() {
    return (
      <div className='map' id={this.props.id}>
      </div>
    );
  }
}

export default MapReport