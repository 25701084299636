import React from 'react'
import PropTypes from 'prop-types'

import autocomplete from "jquery-autocomplete"

class Search extends React.Component {
  static propTypes = {
    autocompleteURL: PropTypes.string,
    csrf: PropTypes.string,
    searchURL: PropTypes.string,
    collapsible: PropTypes.bool
  }

  static defaultProps = {
    collapsible: true
  }

  constructor(props) {
    super(props)
    this.state = {
      query: ""
    }
  }

  handleSubmit(e) {
    e.preventDefault()

    // Autocomplete doesn't callback when a selection is made,
    // so update the state value first, then handle submit
    this.setState({
      query: $("#query").val()
    }, this.handleSubmitAfterSetState);
  }

  handleSubmitAfterSetState() {
    if (!this.state.query || this.state.query.length < 1) {
      $("#query").select();
    } else {
      // Use a GET instead of a POST if we can,
      // so we can save query params in URL for bookmarking
      var query = $("#query").serialize();
      window.location.href = this.props.searchURL + "?" + query;
    }
  }

  changeQuery(e) {
    this.setState({
      query: e.target.value
    });
  }

  componentDidMount() {
    $('form').each(function() {
      $(this).find('input').keypress(function(e) {
        // Enter pressed?
        if(e.which == 10 || e.which == 13) {
          this.form.submit();
        }
      });

      $(this).find('#search-submit').hide();
    });
  }

  handleCollapsible() {
    if(this.props.collapsible === true) {
      return "hidden-md-down"
    } else {
      return ""
    }
  }

  render() {
    return (
      <div className={"search " + this.handleCollapsible()}>
        <form className="search-form form-inline" role="search" acceptCharset="UTF-8" action={this.props.searchURL} method='post' onSubmit={this.handleSubmit.bind(this)} autoComplete="off" >
          <input type='hidden' name='authenticity_token' value={this.props.csrf}/>
          <div className="input-container">
            <input id="query" className="form-control" type="search" placeholder="Search" name="query" value={this.state.query} onChange={this.changeQuery.bind(this)} tabIndex="0" style={{"width": '100%'}}/>
            <div className="underline"></div>
            <input type="submit" id="search-submit"/>
          </div>
        </form>
      </div>
    );
  }
}

export default Search