import React from 'react'
import PropTypes from 'prop-types'

import Comments from "./comments.jsx"

class RenderActionItemComments extends React.Component {
  static propTypes = {
    createActionItemCommentUrl: PropTypes.string,
    userId: PropTypes.number,
    actionItem: PropTypes.object
  }

  render() {
    return (
      <div>
        <Comments name="comment" action={this.props.createActionItemCommentUrl}>
          <input type="hidden" name="comment[user_id]" value={this.props.userId}></input>
          <input type="hidden" name="comment[action_item_id]" value={this.props.actionItem.id}></input>
        </Comments>
      </div>
    );
  }
}

export default RenderActionItemComments
