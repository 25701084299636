import React from 'react'
import PropTypes from 'prop-types'

// Change workflow state
// Afrom r and remove us
import Humanize from "humanize-plus"
import AjaxForm from "./ajax_form.js.jsx"
import Comments from "./comments.jsx"

class ActionItemTags extends React.Component {
  static propTypes = {
    actionItem: PropTypes.object,
    actionItemUsers: PropTypes.array,
    actionItemUpdateUrl: PropTypes.string,
    updateActionItemUserUrl: PropTypes.string,
    taggableUsers: PropTypes.array,
    actionItemWorktypes: PropTypes.array,
    taggableWorktypes: PropTypes.array
  }

  componentDidMount() {
    $("#demo-input-pre-populated").tokenInput(this.props.taggableUsers, {
      prePopulate: this.props.actionItemUsers
    });

    $("#worktype-input-pre-populated").tokenInput(this.props.taggableWorktypes, {
      prePopulate: this.props.actionItemWorktypes
    });
  }

  optionUser() {
    return (
      <div>
        <input type="text" className="form-control" id="demo-input-pre-populated" name="user_id" />
        <p></p>
        <button type="submit" value="Submit" className="btn form-control btn-gradient-success">Update</button>
      </div>
    );
  }

  optionWorktype() {
    return (
      <div>
        <input type="text" className="form-control" id="worktype-input-pre-populated" name="worktype" />
        <p></p>
        <button type="submit" value="Submit" className="btn form-control btn-gradient-success">Update</button>
      </div>
    );
  }

  renderWorktypes() {
    var worktypes = []
    this.props.taggableWorktypes.forEach(function(element) {
      worktypes.push(element.name)
    })
    return (
      <div>
        Available tags: {worktypes.join(", ")}
        <p></p>
      </div>
    )
  }

  render() {
    var humanizedWorkflowState = Humanize.capitalize(this.props.actionItem.workflow_state.replace("_", " "));
    return (
      <div>
        <AjaxForm name="action_item_tags" action={this.props.updateActionItemUserUrl} method="post" >
        <p></p>
        {this.optionUser()}
        <hr></hr>
        <p className="roboto">Update Worktype</p>
        <p></p>
        {this.renderWorktypes()}
        {this.optionWorktype()}
        <hr></hr>
        </AjaxForm>
      </div>
    );
  }
}

export default ActionItemTags
