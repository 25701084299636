import React from 'react'
import PropTypes from 'prop-types'

class Client extends React.Component {
  static propTypes = {
    fullName: PropTypes.string,
    ssn: PropTypes.string,
    birthdate: PropTypes.string,
    gender: PropTypes.string,
    clientUrl: PropTypes.string,
    clientImage: PropTypes.string,
  }

  render() {
    return (
      <div className="col-lg-3">
        <div className="card">
          <div className="card-body">
            <h5 className='card-title'>
              <a href={this.props.clientUrl}>
                {this.props.fullName}
              </a>
            </h5>
            <span>
            {this.props.birthdate && (
              <div>
                <strong>DOB:</strong> {this.props.birthdate}
                <br></br>
              </div>
            )}
            {this.props.ssn && (
              <div>
                <strong>SSN:</strong> {this.props.ssn}
                <br></br>
              </div>
            )}
            {this.props.gender && (
              <div>
                <strong>Gender:</strong> {this.props.gender}
                <br></br>
              </div>
            )}
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default Client
