import React from 'react'
import PropTypes from 'prop-types'

import AjaxForm from "./ajax_form.js.jsx"

class CampTrashSettings extends React.Component {
  static propTypes = {
    trashCampUrl: PropTypes.string
  }

  renderSettings() {
    var onTrashCampSuccess = function() {
      window.location = "/dashboard/camps/";
    };

    return (
       <div className="card">
        <div className="card-body">
          <p className="lead">Trash Bin</p>
          <p>Deleting this camp will remove the camp and all of its associated data, including media, comments, action items, encounters, and paths.
          </p>
          <hr/>
          <AjaxForm name="camp" action={this.props.trashCampUrl} method="POST" onSuccess={onTrashCampSuccess} confirmMessage={"Are you sure you want to delete this camp and any data associated with it?"}>
            <input type="hidden" name="_method" value="delete" />
            <button type="submit" className={"btn btn-danger"}>
              <i className="far fa-trash-alt"/> Delete Camp
            </button>
          </AjaxForm>
        </div>
      </div>
    );
  }

  render() {
    return(
      <div>
        {this.renderSettings()}
      </div>
    )
  }
}

export default CampTrashSettings